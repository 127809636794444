@value colors: 'styles/colors.module.css';
@value SUB_TEXT_GREY from colors;

.container {
  display: flex;
  align-items: center;
}

.labelWrapper {
  margin-left: 1.25rem;
}

.subText {
  color: SUB_TEXT_GREY;
  margin-top: 0.375rem;
}
