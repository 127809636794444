@value colors: 'styles/colors.module.css';
@value CHARCOAL, PURPLE from colors;
@value SIZE: 1.4rem;
@value INNER_SIZE: 70%;

.container {
  display: inline-block;
}

.radioInput {
  border: 0;
  clip: rect(0 0 0 0);
  clippath: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}

.radio {
  display: flex;
  justify-content: center;
  align-items: center;
  width: SIZE;
  height: SIZE;
  border: solid 2px CHARCOAL;
  border-radius: 50%;
}

.radioIcon {
  width: INNER_SIZE;
  height: INNER_SIZE;
}
