@value colors: 'styles/colors.module.css';
@value BLUE_GRAYS_01, BLUE_GRAYS_05 from colors;
@value breakpoints: 'styles/breakpoints.module.css';
@value DESKTOP_LG_WIDTH, DESKTOP_MED_WIDTH, DESKTOP_WIDTH, TABLET_WIDTH from breakpoints;

.detailItem {
  display: flex;
  justify-content: flex-start;
  gap: 0.625rem;
  margin-bottom: 0.5rem;
}

.detailItem:last-child {
  margin-bottom: 0;
}

.checkCircle {
  display: flex;
  width: 0.875rem;
  height: 0.875rem;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 0.5625rem;
  border-radius: 1.9375rem;
  background: BLUE_GRAYS_01;
  margin-top: 0.125rem;
  overflow: auto;
}

.detailText {
  color: BLUE_GRAYS_05;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 900;
  line-height: normal;
  margin-top: 0.0625rem;
  overflow: wrap;
  flex: 1;
}

@media screen and (min-width: DESKTOP_WIDTH) {
  .checkCircle {
    width: 1.125rem;
    height: 1.125rem;
    margin-top: 0.25rem;
  }

  .checkIcon {
    height: 0.5rem;
    width: 0.5rem;
  }

  .detailText {
    font-size: 1rem;
  }
}