@value colors: 'styles/colors.module.css';
@value WHITE, GREY_3, ORANGE, PLACEHOLDER_GREY, GREY_1, PURPLE, NEUTRAL_GRAY_50, NEUTRAL_GRAY_200, NEUTRAL_GRAY_900 from colors;

@value breakpoints: 'styles/breakpoints.module.css';
@value DESKTOP_WIDTH from breakpoints;

.wrapper {
  width: 100%;
  position: relative;
}

.inputWrapper {
  display: flex;
  position: relative;
}

.addNewButton {
  composes: base from 'styles/buttons.module.css';
  composes: small from 'styles/buttons.module.css';
  composes: solidOrange from 'styles/buttons.module.css';
  flex-shrink: 0;
  margin-left: 0.5rem;
}

.input {
  composes: base from 'styles/inputs.module.css';
  composes: block from 'styles/inputs.module.css';
}

.inputV2 {
  composes: baseV2 from 'styles/inputs.module.css';
  composes: block from 'styles/inputs.module.css';
}

.inputV2::placeholder {
  color: PLACEHOLDER_GREY;
}

.placeholderIcon {
  color: PLACEHOLDER_GREY;
  position: absolute;
  top: 55%;
  right: 0;
  transform: translate(-50%, -50%);
}

.options {
  position: absolute;
  z-index: 3;
  width: 100%;
  top: 100%;
  background-color: WHITE;
  border: solid 1px GREY_3;
  border-radius: 4px;
  max-height: 300px;
  overflow: scroll;
}

.optionsTop {
  top: 0;
  transform: translateY(-100%);
}

.optionsHidden {
  display: none;
}

.option {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 0.5rem;
  padding: 0.5rem 1rem;
}

.option div:first-child {
  grid-column: span 2 / auto;
  overflow: hidden;
}

.option.span3 div:first-child {
  grid-column: span 3 / auto;
}

.option:not(:last-child) {
  border-bottom: solid 1px GREY_3;
}

.textWrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}


.textWrapper h2 {
  font-size: 1rem;
  line-height: 1.5rem;
  font-weight: 500;
}

.imageWrapper {
  position: relative;
  width: 100%;
  border-radius: 0.25rem;
  overflow: hidden;
  margin: 0;
  aspect-ratio: 1/1;
  border: 1px solid GREY_3;
  max-height: 85px;
}

.tagWrapper {
  display: flex;
  flex-wrap: wrap;
}

.tag {
  margin-top: 0.25rem;
  margin-right: 0.25rem;
  background-color: NEUTRAL_GRAY_200;
  border-radius: 0.25rem;
  text-transform: uppercase;
  padding: 0rem 0.25rem;
  font-size: 0.625rem;
  line-height: 1rem;
  font-weight: 500;
  letter-spacing: .08rem;
  color: NEUTRAL_GRAY_900;
  white-space: nowrap;
  text-decoration: none;
}

.match {
  font-weight: 900;
}

@media (hover: hover) {
  .option:hover {
    color: PURPLE;
    cursor: pointer;
    background: NEUTRAL_GRAY_50;
  }
}

.sections {
  display: flex;
  flex-direction: column;
  margin: 0.4rem 0;
  flex: 1;
  min-height: 0;
}

.section {
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  height: 50%;
}

.section:not(:last-child) {
  margin-bottom: 12px;
}

.sectionName {
  font-weight: 500;
  font-size: 1.2rem;
  text-transform: uppercase;
  text-align: center;
  padding: 0.4rem 0.8rem;
  border-radius: 4px;
  border: 1px solid GREY_3;
  margin-bottom: 12px;
}

.sectionOptions {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow-y: auto;
}

.sectionOption {
  position: relative;
  border-radius: 4px;
  border: 1px solid GREY_1;
  cursor: pointer;
}

.sectionOptionText {
  display: flex;
  align-items: center;
  width: 100%;
  position: relative;
}

.sectionOptionBlock {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  background: rgba(245, 247, 249, 0.7);
}

.sectionOption:not(:last-child) {
  margin-bottom: 12px;
}