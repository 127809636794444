@value colors: 'styles/colors.module.css';
@value CHARCOAL, SEA_BLUE, GREY_BLUE from colors;

@value breakpoints: 'styles/breakpoints.module.css';
@value DESKTOP_LG_WIDTH, DESKTOP_MED_WIDTH, MOBILE_WIDTH_MAX, TABLET_WIDTH from breakpoints;

.app {
  -webkit-font-smoothing: antialiased;
  color: CHARCOAL;
  min-height: 100vh;
  margin: 0 auto;
}

.app * {
  box-sizing: border-box;
}

.app pre {
  word-wrap: break-word;
  overflow-wrap: break-word;
  white-space: pre-wrap;
}

.modal {
  padding: 1rem;
  position: fixed;
  z-index: 1001;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: max-content;
  max-width: 100%;
  max-height: 100%;
  overflow-y: scroll;
}

.modalOverlay {
  position: fixed;
  z-index: 1000;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.modalOverlayWhite {
  background-color: rgba(255, 255, 255, 0.6);
}

.modalOverlayBlack {
  background-color: rgba(0, 0, 0, 0.7);
}