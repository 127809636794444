@value colors: 'styles/colors.module.css';
@value CHARCOAL from colors;
@value SIZE: 1.5625rem;

.container {
  display: inline-block;
}

.checkboxInput {
  border: 0;
  clip: rect(0 0 0 0);
  clippath: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}

.checkbox {
  display: flex;
  justify-content: center;
  align-items: center;
  width: SIZE;
  height: SIZE;
  border: solid 2px CHARCOAL;
  border-radius: 0.1875rem;
}

.checkIcon {
  width: 1.125rem;
  height: 0.875rem;
}
