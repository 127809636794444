.flex {
  display: flex;
}

.flexRow {
  composes: flex;
  flex-direction: row;
}

.flexColumn {
  composes: flex;
  flex-direction: column;
}

.justifyStart {
  justify-content: flex-start;
}

.justifyBetween {
  justify-content: space-between;
}

.justifyAround {
  justify-content: space-around;
}

.justifyCenter {
  justify-content: center;
}

.justifyEnd {
  justify-content: flex-end;
}

.itemsStart {
  align-items: flex-start;
}

.itemsCenter {
  align-items: center;
}

.itemsEnd {
  align-items: flex-end;
}

.itemsStretch {
  align-items: stretch;
}

.flex1 {
  flex: 1 1 0%;
}

.wrap {
  flex-wrap: wrap;
}
