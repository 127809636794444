.container {
  display: flex;
}

.input {
  border: 0px;
  clip: rect(0px, 0px, 0px, 0px);
  height: 1px;
  width: 1px;
  margin: -1px;
  padding: 0px;
  overflow: hidden;
  opacity: 0;
  pointer-events: none;
}

.container .input:checked ~ svg {
  fill: #475569;
}

.container svg {
  fill: #e0e0e0;
  pointer-events: none;
}
