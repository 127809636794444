@value colors: 'styles/colors.module.css';
@value SECONDARY_500, WHITE from colors;

.bannerSection {
  display: flex;
  background-color: SECONDARY_500;
  height: 50px;
  width: 100%;
  z-index: 10000;
  top: 0;
  position: fixed;
}

.header {
  color: WHITE;
  margin: auto;
  padding: 0 0.5rem;
  text-align: center;
  font-weight: 600;
}
