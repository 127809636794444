@value colors: 'styles/colors.module.css';
@value WHITE, ORANGE, NEUTRAL_GRAY_50, NEUTRAL_GRAY_200, NEUTRAL_GRAY_300, NEUTRAL_GRAY_600, ERROR_50, ERROR_600 from colors;

.question {}

.header {
  font-size: 1.5rem;
  margin-bottom: 1.375rem;
}

.headerB {
  font-size: 1.5rem;
  margin-bottom: 0.5rem;
}

.note {
  margin-top: 2.4375rem;
  font-size: 1.125rem;
  white-space: pre-wrap;
}

.supportingText {
  font-size: 18px;
  line-height: 28px;
  color: NEUTRAL_GRAY_600;
}

.textArea {
  width: 23rem;
  height: 5.625rem;
}

.nextButton {
  composes: mediumV2 from 'styles/buttons.module.css';
  composes: solidPurple from 'styles/buttons.module.css';
  font-weight: 600;
  text-transform: none;
  margin-top: 1.5rem;
}

.input {
  width: 100%;
  max-width: 400px;
}

.ageWrapper {
  display: flex;
  align-items: center;
}

.ageWrapperB {
  display: flex;
  font-size: 0.875rem;
  color: NEUTRAL_GRAY_600;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 2.5rem;
}

.ageLabel {
  font-size: 0.875rem;
  margin-right: 0.5rem;
}

.ageInput {
  background-color: WHITE;
  padding: 0.875rem;
  border: solid 1px rgba(0, 0, 0, 0.25);
  border-radius: 0.5rem;
}

.error {
  color: ORANGE;
  margin-top: 0.4rem;
}

.ageHelperText {
  margin-top: 0.5rem;
}

.errorInput {
  background-color: ERROR_50;
  border: 1px solid ERROR_600;
}