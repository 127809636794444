@value colors: 'styles/colors.module.css';
@value SUB_TEXT_GREY, NEUTRAL_GRAY_50, NEUTRAL_GRAY_200, NEUTRAL_GRAY_300, NEUTRAL_GRAY_600 from colors;
@value breakpoints: 'styles/breakpoints.module.css';
@value DESKTOP_LG_WIDTH, DESKTOP_MED_WIDTH, DESKTOP_WIDTH, TABLET_WIDTH from breakpoints;

.optionWrapper {
  margin-bottom: 1.625rem;
}

.option {
  display: flex;
  align-items: flex-start;
}

.labelWrapper {
  margin-left: 1.25rem;
}

.label {
  font-size: 1.125rem;
}

.nestedText {
  padding: 0.6875rem 0 0 2.875rem;
}

.nestedSelect {
  padding: 1.5625rem 0 0 3.5rem;
}

.subText {
  color: SUB_TEXT_GREY;
  margin-top: 0.375rem;
}

.optionWrapperB {
  margin-bottom: 1rem;
  padding: 1rem;
  background-color: NEUTRAL_GRAY_50;
  cursor: pointer;
}

.optionWrapperB.selected {
  background-color: NEUTRAL_GRAY_300;
}

.labelWrapperB {
  margin-left: 1rem;
}

.labelB {
  font-size: 1rem;
  font-weight: 500;
  color: NEUTRAL_GRAY_600;
}

.nestedTextB {
  padding-left: 2.25rem;
  padding-top: 0.5rem;
}

.helperText {
  display: block;
  font-size: 1rem;
  line-height: 1.5rem;
  color: NEUTRAL_GRAY_600;
  margin-bottom: 1rem;
}

.onlyCheckboxClickable {
  cursor: default;
  padding: 1rem 0.125rem;
}

@media screen and (min-width: DESKTOP_WIDTH) {
  .optionWrapperB:hover {
    box-shadow: 0px 9px 18px rgba(0, 0, 0, 0.15);
    background-color: NEUTRAL_GRAY_200;
  }

  .optionWrapperB:hover svg {
    fill: #475569;
  }

  .optionWrapperB.selected:hover {
    box-shadow: 0px 9px 18px rgba(0, 0, 0, 0.15);
    background-color: NEUTRAL_GRAY_200;
  }
}