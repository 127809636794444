@value colors: 'styles/colors.module.css';
@value ORANGE from colors;

.container {
}

.inputWrapper {
  position: relative;
  width: 18.8125rem;
  max-width: 100%;
}

.input {
  composes: baseV2 from 'styles/inputs.module.css';
  padding-right: 3rem;
  width: 100%;
}

.label {
  position: absolute;
  top: 50%;
  right: 0.8125rem;
  transform: translateY(-50%);
  font-size: 1.125rem;
}

.error {
  color: ORANGE;
  font-size: 0.9rem;
  margin-top: 0.5rem;
}
