@value colors: '~styles/colors.module.css';
@value CHARCOAL, BACKGROUND_PURPLE, BACKGROUND_ORANGE, BACKGROUND_GREY from colors;

.root {
  align-items: center;
  background-color: BACKGROUND_GREY;
  border-radius: calc(32px / 2);
  color: rgba(15, 23, 42, 1);
  cursor: pointer;
  display: inline-flex;
  justify-content: center;
  height: 32px;
  font-size: 0.82rem;
  padding: 8px 16px;
  text-decoration: none;
  white-space: nowrap;
}

.root:hover {
  background-color: #cccccc;
}

.active {
  background-color: CHARCOAL;
  color: #ffffff;
}

.active:hover {
  background-color: #424242;
}

.disabled {
  cursor: initial;
  pointer-events: none;
  opacity: 0.6;
}

.label {
  overflow: hidden;
  text-overflow: ellipsis;
  user-select: none;
}

.icon {
  margin-right: 8px;
}
