@value colors: 'styles/colors.module.css';
@value ORANGE, GREEN, SUB_TEXT_GREY from colors;

.container {
}

.image {
  display: block;
  max-width: 40rem;
  margin-top: 1rem;
  width: 100%;
  image-orientation: from-image;
}

.fileInputWrapper {
  display: block;
}

.fileInput {
  opacity: 0;
  position: absolute;
  pointer-events: none;
}

.button {
  composes: baseV2 from 'styles/buttons.module.css';
  composes: smallV2 from 'styles/buttons.module.css';
  display: block;
  width: 200px;
}

.fileInputButton {
  composes: button;
  composes: hollowPurple from 'styles/buttons.module.css';
}

.icon {
  margin-right: 0.5rem;
  vertical-align: text-bottom;
}

.deleteButton {
  composes: button;
  composes: hollowOrange from 'styles/buttons.module.css';
}

.fileInputButtonDisabled {
  opacity: 0.6;
  pointer-events: none;
}

.error {
  color: ORANGE;
  margin-top: 0.4rem;
}

.processingNote {
  color: GREEN;
  margin-top: 0.4rem;
}

.processingText {
  margin: 0.5rem 0;
}
