@value colors: 'styles/colors.module.css';
@value ACCENT_GREY, PURPLE from colors;

.reengagementHeader {
  font-weight: 600;
  font-size: 1.125rem;
  line-height: 1;
  letter-spacing: -0.025em;
  text-align: center;
}

.reengagementBody {
  margin-top: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.reengagementText {
  text-align: center;
}

.reengagementLink {
  margin-top: 1rem;
}

.inputContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 1rem;
}

.input {
  font-size: 0.875rem;
  padding: 0.5rem 0.75rem;
  border-radius: 0.5rem;
  border: 1px solid #e4e4e7;
  text-align: center;
  font-weight: 600;
}

.buttons {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 1rem;
}

.retryAction {
  margin-right: 1rem;
  margin-top: 1rem;
}

.retryButton {
  composes: retryAction;
  composes: baseV2 from 'styles/buttons.module.css';
  composes: smallV2 from 'styles/buttons.module.css';
  composes: solidPurple from 'styles/buttons.module.css';
}

button.retryButtonB {
  margin-top: 1rem;
  font-size: 0.875rem;
  font-weight: 600;
  line-height: 1.25rem;
  padding: 0.5rem 1rem;
  border-radius: 0.5rem;
}

.retryConfirmation {
  composes: retryAction;
  font-size: 0.875rem;
  font-weight: 600;
  color: PURPLE;
}

.cancelReengagementButton {
  composes: unstyled from 'styles/buttons.module.css';
  background-color: #f4f4f5;
  margin-top: 1rem;
  font-size: 0.875rem;
  font-weight: 600;
  line-height: 1.25rem;
  padding: 0.5rem 1rem;
  border-radius: 0.5rem;
}