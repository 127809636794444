.confirmModal {
  background-color: WHITE;
  padding: 1rem;
  border-radius: 0.25rem;
  box-shadow: 0 0 0.5rem 0 rgba(0, 0, 0, 0.3);
  max-width: 25rem;
  min-width: 20rem;
  margin: 0 auto;
}

.prompt {
  margin-bottom: 1rem;
}

.buttons {
  display: flex;
  justify-content: space-between;
}

.button {
  composes: baseV2 from 'styles/buttons.module.css';
  composes: smallV2 from 'styles/buttons.module.css';
  width: 48%;
  display: block;
}

.confirmButton {
  composes: button;
  composes: solidGreen from 'styles/buttons.module.css';
}

.confirmButtonB {
  width: 48%;
}

.confirmButton:disabled {
  opacity: 0.3;
}

.cancelButton {
  composes: button;
  composes: solidSilver from 'styles/buttons.module.css';
}

.cancelButtonB {
  width: 48%;
}

.inputText {
  width: 100%;
  margin-bottom: 1rem;
}

.bottomLink {
  width: 100%;
  color: purple;
  text-align: center;
  font-weight: 500;
  display: block;
  padding: 500;
  margin-top: 15px;
}

.spinner > svg {
  color: inherit;
  font-size: inherit;
  margin-left: 0.5rem;
}
