.h1 {
  font-weight: 500;
  font-size: 36px;
  line-height: 48px;
}

.h2 {
  font-weight: 500;
  font-size: 24px;
  line-height: 32px;
}

.h3 {
  font-weight: 500;
  font-size: 18px;
  line-height: 28px;
}

.p {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  font-weight: normal;
}

.span {
  font-size: 16px;
  line-height: 24px;
}

.label {
  font-size: 14px;
  line-height: 20px;
}
