@value colors: 'styles/colors.module.css';
@value GREY_2, RED, ACCENT_GREY from colors;

.wrapper {
}

.inputWrapper {
  position: relative;
}

.input {
  composes: baseTextArea from 'styles/inputs.module.css';
  resize: vertical;
}

.inputInvalid {
  composes: errorV2 from 'styles/inputs.module.css';
}

.label {
  color: GREY_2;
  font-size: 0.9rem;
  margin-bottom: 0.4rem;
}

.characterCount {
  color: ACCENT_GREY;
}

.error {
  color: RED;
  font-weight: 500;
  margin-top: 0.4rem;
}
