@value colors: 'styles/colors.module.css';
@value PURPLE, WHITE, BLUE_GRAYS_00, BLUE_GRAYS_02, BLUE_GRAYS_04, BLUE_GRAYS_05, BLUE_GRAYS_SEMI_BLACK from colors;
@value breakpoints: 'styles/breakpoints.module.css';
@value DESKTOP_LG_WIDTH, DESKTOP_MED_WIDTH, DESKTOP_WIDTH, TABLET_WIDTH from breakpoints;

.planCardContainer {
  position: relative;
  display: flex;
  padding: 1.25rem;
  flex-direction: column;
  /* justify-content: center; */
  align-items: flex-start;
  gap: 1.25rem;
  align-self: stretch;
  border-radius: 1rem;
  background: WHITE;
  box-shadow: 0rem 0.625rem 1.875rem 0rem rgba(0, 0, 0, 0.08);
  border: 0.125rem solid WHITE;
  width: 100%;
  height: inherit;
  text-align: left;
}

.planCardContainer:hover {
  border-radius: 1rem;
  background: BLUE_GRAYS_00;
  border: 0.125rem solid BLUE_GRAYS_00;
  cursor: pointer;
}

.planCardContainer:active {
  opacity: 0.4;
}

.recommendedTag {
  position: absolute;
  top: -1rem;
  right: -0.5rem;
  display: flex;
  padding: 0.375rem 0.75rem;
  justify-content: center;
  align-items: center;
  gap: 0.5625rem;
  border-radius: 1.25rem;
  background: BLUE_GRAYS_05;
  box-shadow: 0rem 0.5rem 1.25rem 0rem rgba(0, 0, 0, 0.10);
}

.recommendedTagText {
  color: WHITE;
  font-size: 0.6875rem;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
  letter-spacing: 0.0275rem;
  text-transform: uppercase;
}

.selected,
.selected:hover {
  border: 0.125rem solid BLUE_GRAYS_02;
}

.planCardHeader {
  display: flex;
  align-items: center;
  justify-content: stretch;
  width: 100%;
}

.planCardImageWrapper {
  position: relative;
  width: 3.125rem;
  height: 3.125rem;
}

.planCardImageOne {
  border-radius: 0.5rem;
  object-fit: cover;
}

.planCardRotatedLeftImage {
  width: 1.8550625rem;
  height: 1.8550625rem;
  transform: rotate(-10deg);
  border-radius: 0.375rem;
}

.planCardRotatedRightImage {
  width: 2rem;
  height: 2rem;
  transform: rotate(10.484deg);
  border-radius: 0.375rem;
  border: 0.125rem solid WHITE;
  position: absolute;
  right: 0.3125rem;
  bottom: 0.3125rem;
}

.planCardTitleWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 0.5rem;
  margin-left: 0.75rem;
  flex: 1;
}

.planCardTitle {
  color: BLUE_GRAYS_SEMI_BLACK;
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
}

.planCardSelectionDot {
  display: flex;
  justify-self: flex-end;
}

.planCardPriceWrapper {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  align-self: stretch;
}

.planCardDailyPrice {
  color: BLUE_GRAYS_SEMI_BLACK;
  font-size: 1.875rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.planCardDailyPriceText {
  color: BLUE_GRAYS_SEMI_BLACK;
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 900;
  line-height: normal;
}

.planCardMonlthlyPrice {
  color: BLUE_GRAYS_04;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 900;
  line-height: normal;
  text-transform: capitalize;
}

.planCardAfterTrialMonthlyPrice {
  display: inline-flex;
  color: BLUE_GRAYS_04;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 900;
  line-height: normal;
  margin-top: 0.5rem;
}

@media screen and (min-width: TABLET_WIDTH) {
  .planCardPriceWrapper {
    flex-direction: column;
    align-items: flex-start;
    gap: 0.625rem;
  }
}