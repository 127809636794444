@value colors: 'styles/colors.module.css';
@value PURPLE, WHITE, BLUE_GRAYS_03 from colors;
@value breakpoints: 'styles/breakpoints.module.css';
@value DESKTOP_LG_WIDTH, DESKTOP_MED_WIDTH, DESKTOP_WIDTH, TABLET_WIDTH from breakpoints;

.selectionCircle {
  display: flex;
  width: 1.25rem;
  height: 1.25rem;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 2.5rem;
  border: 0.125rem solid BLUE_GRAYS_03;
}

.notSelected {
  width: 0.625rem;
  height: 0.625rem;
}

.selected {
  width: 0.625rem;
  height: 0.625rem;
  border-radius: 1.25rem;
  background: linear-gradient(243deg, #E57054 0%, #85254E 100%);
}