@value colors: 'styles/colors.module.css';
@value GREY_2, ORANGE from colors;

.wrapper {
}

.inputWrapper {
  position: relative;
}

.icon {
  position: absolute;
  left: 17px;
  top: 50%;
  transform: translate(-50%, -50%);
  color: GREY_2;
}

.input {
  composes: base from 'styles/inputs.module.css';
}

.iconInput {
  padding-left: 34px;
}

.inputV2 {
  composes: baseV2 from 'styles/inputs.module.css';
}

.inputInvalid {
  composes: error from 'styles/inputs.module.css';
}

.label {
  color: GREY_2;
  font-size: 0.9rem;
  margin-bottom: 0.4rem;
}

.error {
  color: ORANGE;
  margin-top: 0.4rem;
}
