@value colors: 'styles/colors.module.css';
@value PURPLE, BACKGROUND_PURPLE, CHARCOAL from colors;

.values {
  display: flex;
  flex-wrap: wrap;
}

.value {
  padding: 0.2rem 0.4rem;
  background-color: rgba(131, 0, 81, 0.2);
  color: PURPLE;
  border-radius: 4px;
  margin-right: 0.5rem;
  margin-bottom: 0.5rem;
}

.deleteButton {
  composes: unstyled from 'styles/buttons.module.css';
  padding: 0.2rem;
  margin: -0.2rem;
  margin-left: 0.2rem;
}

.deleteIcon {
  color: PURPLE;
}
