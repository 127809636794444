@value colors: 'styles/colors.module.css';
@value WHITE, GREY_3, NIGHT_BLUE, PURPLE, GREEN, PLACEHOLDER_GREY from colors;

.sharedBase {
  font-size: 1rem;
  font-family: inherit;
}

.base {
  composes: sharedBase;
  padding: 0.4rem 0.6rem;
  border-radius: 4px;
  background-color: WHITE;
  border: solid 1px GREY_3;
  box-shadow: none;
  outline: none;
  transition: border-color 0.2s;
  color: NIGHT_BLUE;
}

.baseV2 {
  composes: sharedBase;
  border: none;
  border-radius: 0;
  border-bottom: solid 1px rgba(0, 0, 0, 0.25);
  padding: 0.875rem 0;
  font-size: 1.125rem;
  background-color: transparent;
  outline: none;
}

.sharedBase:focus {
  border-color: PURPLE;
}

.noBrowserStyles {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.underline {
  border-top-width: 0;
  border-right-width: 0;
  border-left-width: 0;
  border-radius: 0;
  /* border-bottom-width: 1; */
}

.base::placeholder {
  color: #c7c7cd;
}

.baseV2::placeholder {
  color: PLACEHOLDER_GREY;
}

.block {
  display: block;
  width: 100%;
}

.error {
  border-color: #e14154;
}

.error:focus {
  border-color: #e14154;
}

.valid {
  border-color: GREEN;
}

.valid:focus {
  border-color: GREEN;
}

.mediumSize {
  font-size: 14px;
  padding: 8px;
}

.largeSize {
  font-size: 16px;
  padding: 8px 10px;
  height: 36px;
}

.baseTextArea {
  composes: base;
  max-width: 100%;
  min-width: 100%;
  height: 10rem;
}

.baseTextAreaV2 {
  composes: sharedBase;
  border: 1px solid rgba(0, 0, 0, 0.25);
  border-radius: 0.25rem;
  font-size: 1.125rem;
  max-width: 100%;
  min-width: 100%;
  height: 10rem;
}

.baseTextAreaV2::placeholder {
  color: PLACEHOLDER_GREY;
}
