@value breakpoints: 'styles/breakpoints.module.css';
@value TABLET_WIDTH from breakpoints;

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.header {
  font-size: 1.125rem;
  font-weight: 600;
  margin-bottom: 2.125rem;
  text-align: center;
}

.weightLoss {
  width: 100%;
  height: auto;
}

.greenText {
  font-weight: 800;
  background: linear-gradient(270deg, #4DD182 0%, #009946 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.greenBackground {
  padding: 0.125rem 0.25rem;
  justify-content: center;
  align-items: center;
  border-radius: 0.25rem;
  background: #ECFFF6;
}

.imageContainer {
  position: relative;
  border-radius: 0.625rem;
  background: #FFF;
  box-shadow: 0px 10px 30px 0px rgba(0, 0, 0, 0.06);
  display: flex;
  width: 17.5rem;
  padding: 0.625rem;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.625rem;
}

.imageTitle {
  position: absolute;
  top: 0.625rem;
  left: 0.625rem;
}

.imageTitleText {
  font-size: 1.125rem;
  font-weight: 800;
  background: linear-gradient(270deg, #E57054 0%, #85254E 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.imageCaption {
  color: #687189;
  font-size: 0.625rem;
  font-weight: 700;
  line-height: normal;
}

@media screen and (min-width: TABLET_WIDTH) {
  .imageContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 600px;
    height: auto;
    box-shadow: none;
  }

  .imageTitle {
    display: flex;
    padding: 0.5rem 0.625rem;
    justify-content: center;
    align-items: center;
    left: 3.85rem;
    border-radius: 0.5rem;
    background: #F5F8FB;
    box-shadow: 0px 10px 30px 0px rgba(0, 0, 0, 0.08);
  }

  .imageCaption {
    font-size: 0.6875rem;
    text-align: center;
    width: 300px
  }
}