@value colors: 'styles/colors.module.css';
@value NEUTRAL_GRAY_50, NEUTRAL_GRAY_200, NEUTRAL_GRAY_300, NEUTRAL_GRAY_600, SUB_TEXT_GREY from colors;

.option {
  background: NEUTRAL_GRAY_50;
  padding: 1rem !important;
  align-items: flex-start !important;
}

:global(.--has-hover) .option:hover, :global(.--has-hover) .optionChecked:hover {
  box-shadow: 0px 9px 18px rgba(0, 0, 0, 0.15);
  background-color: NEUTRAL_GRAY_200;
}

.optionChecked {
  background: NEUTRAL_GRAY_300;
}

.label {
  font-weight: 500;
  color: NEUTRAL_GRAY_600;
}

.subText {
  color: SUB_TEXT_GREY;
  margin-top: 0.375rem;
}

.icon {
  margin-top: 2px;
  color: NEUTRAL_GRAY_600;
}
