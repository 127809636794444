@value colors: 'styles/colors.module.css';
@value PURPLE from colors;
@value breakpoints: 'styles/breakpoints.module.css';
@value TABLET_WIDTH from breakpoints;

.link {
  color: PURPLE;
}

.h {
  font-weight: 500;
  margin-bottom: 0.75rem;
}

.h1 {
  composes: h;
  font-size: 1.875em;
}

.h2 {
  composes: h;
  font-size: 1.5625em;
}

.h3 {
  composes: h;
  font-size: 1.375em;
}

.h4 {
  composes: h;
  font-size: 1.25em;
}

.h5 {
  composes: h;
  font-size: 1.125em;
}

.h6 {
  composes: h;
  font-size: 1em;
}

.bold {
  font-weight: 700;
}

.underline {
  text-decoration: underline;
}

.italic {
  font-style: italic;
}

.p {
  margin-bottom: 1.5em;
}

.list {
  margin: 0 0 1.25em 1.25em;
}

.ul {
  composes: list;
  list-style: disc;
}

.ul ul {
  list-style: circle;
}

.ul ul ul {
  list-style: square;
}

.ol {
  composes: list;
  list-style: decimal;
}

.li .p {
  margin-top: .5rem;
  margin-bottom: 0;
}

.blockquote {
  margin-bottom: 1.3125em;
  padding-left: 0.875em;
  border-left: solid 6px PURPLE;
}

.image {
  display: block;
  margin: 0 auto 1.25em;
  max-width: 100%;
  aspect-ratio: 4/3;
  position: relative;
  overflow: hidden;
}

.video {
  display: block;
  margin: 0 auto 1.25em;
  max-width: 100%;
  overflow: hidden;
  min-height: 100%;
  max-height: 25rem;
}

.firstLetter {
  composes: bold;
  font-size: 2em;
}

@media screen and (min-width: TABLET_WIDTH) {
  .image {
    aspect-ratio: 16/9;
  }
}