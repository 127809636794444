@value breakpoints: 'styles/breakpoints.module.css';
@value MOBILE_WIDTH_MAX from breakpoints;

.container {
  position: relative;
  overflow: hidden;
}

.containerMobile {
  display: none !important;
}

.cover {
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  min-width: 100%;
  min-height: 100%;
  width: auto;
  height: auto;
  z-index: -1;
}

.image {
  display: block;
  color: transparent;
  max-width: 100%;
  max-height: 100%;
  object-fit: cover;
}

.imageProgressive {
  composes: cover;
}

.thumbnail {
  composes: cover;
  color: transparent;
}

.video {
  composes: cover;
}

@media screen and (max-width: MOBILE_WIDTH_MAX) {
  .containerDesktop {
    display: none !important;
  }

  .containerMobile {
    display: block !important;
  }
}
