@value colors: 'styles/colors.module.css';
@value ORANGE from colors;

.base {
  color: ORANGE;
  text-decoration: none;
}

.unstyled {
  text-decoration: none;
  color: initial;
}

@media (hover: hover) {
  .hoverUnderline:hover {
    text-decoration: underline;
  }
}
