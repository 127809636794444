@value colors: 'styles/colors.module.css';
@value PURPLE, GREY_3, WHITE, ACCENT_GREY, BORDER_GREY from colors;

@value breakpoints: 'styles/breakpoints.module.css';
@value MOBILE_WIDTH_MAX from breakpoints;

.container {
  display: flex;
  flex-direction: column;
}

.row {
  padding: 15px 20px;
}

.withColumns {
  display: flex;
}

.column {
  flex: 1 1 auto;
  max-width: 100%;
}

.centerHorizontally {
  margin: 0 auto;
}

.center {
  margin: auto;
}

.cta {
  padding: 0 35px;
}

.paymentColumn {
  max-width: 600px;
  padding: 0 35px;
}

.paymentContainer {
  padding: 0;
}

.pricingItems {
  flex: 1;
  text-align: right;
  font-size: 1.125rem;
}

.boldPrice {
  font-size: 1.6rem;
  font-weight: 500;
  color: PURPLE;
}

.pricingItem:not(:last-child) {
  margin-bottom: 0.2rem;
}

.subHeader {
  font-size: 1.25rem;
  margin-bottom: 1rem;
}

.contactInfoRow {
  max-width: 500px;
}

.faqs {
  margin-top: 2rem;
}

.container .product {
  background-color: WHITE;
  border-color: WHITE;
  box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.15);
}

.orWrapper {
  display: flex;
  align-items: center;
  margin-bottom: 25px;
}

.or {
  font-size: 1.25rem;
  margin: 0 1rem;
  color: ACCENT_GREY;
}

.bar {
  flex: 1;
  height: 1px;
  background-color: BORDER_GREY;
}

.payLaterButton {
  composes: baseV2 from 'styles/buttons.module.css';
  composes: mediumV2 from 'styles/buttons.module.css';
  composes: hollowOrange from 'styles/buttons.module.css';
}

.talkToYourCoach {
  margin: 40px 0;
}

.noContracts {
  font-style: italic;
  margin-bottom: 1rem;
}

.textCenter {
  text-align: center;
}

.billingPeriodSelect {
  display: flex;
  justify-content: center;
  margin-bottom: 1rem;
}

.updatePaymentMethod {
  composes: flex itemsCenter justifyCenter from 'styles/flex.module.css';
  min-height: 100dvh;
  margin-top: 0;
  max-width: 350px;
  margin-left: auto;
  margin-right: auto;
}

.loading {
  height: 100dvh;
}

@media screen and (max-width: MOBILE_WIDTH_MAX) {
  .container {
    display: block;
  }

  .row {
    flex-wrap: wrap;
    padding: 5px 20px;
  }

  .cta {
    padding: 0;
  }

  .paymentColumn,
  .paymentContainer {
    padding: 0;
  }

  .cta {
    flex: 1 0 100%;
  }

  .subscribeContainer {
    flex: 1 0 100%;
  }

  .contactInfoRow {
    margin-top: 1rem;
  }

  .billingPeriodSelect {
    margin-top: 1rem;
  }

  .updatePaymentMethod {
    min-height: 100dvh;
    margin-top: 0;
    padding: 1rem;
  }
}