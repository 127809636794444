@value breakpoints: 'styles/breakpoints.module.css';
@value DESKTOP_WIDTH from breakpoints;
@value colors: 'styles/colors.module.css';
@value HORIZONTAL_GRADIENT from colors;

.subtitle {
  display: block;
  font-size: 14px;
  line-height: 24px;
  font-weight: 400;
  text-transform: uppercase;
  margin-bottom: 8px;
}

.title {
  font-size: 1.125rem;
  font-weight: 800;
  background: HORIZONTAL_GRADIENT;
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  padding-bottom: 1rem;
  width: fit-content;
}

.border {
  width: 80px;
  height: 2px;
  border-radius: 3.0625rem;
  background-image: HORIZONTAL_GRADIENT;
}

@media screen and (min-width: DESKTOP_WIDTH) {
  span.subtitle {
    font-size: 18px;
    line-height: 28px;
  }

  .border {
    height: 0.25rem;
  }

  .title {
    font-size: 1.875rem;
    font-weight: 800;
  }
}
