@value breakpoints: 'styles/breakpoints.module.css';
@value MOBILE_WIDTH from breakpoints;

@value colors: 'styles/colors.module.css';
@value DIAGONAL_GRADIENT, GRADIENT_PRIMARY_DEFAULT, GRADIENT_PRIMARY_HOVER from colors;

.button {
  composes: baseV2 from 'styles/buttons.module.css';
  composes: mediumV2 from 'styles/buttons.module.css';
  display: flex;
  flex-direction: row;
  align-items: center;
  bottom: 4rem;
  padding: 0.6rem 0.8rem;
  background-color: WHITE;
  border-color: WHITE;
  box-shadow: 0 6px 12px 0 rgba(0, 0, 0, 0.11);
}

.buttonText {
  padding-left: 0.5rem;
  font-size: 1.125rem;
  font-weight: 500;
  background: DIAGONAL_GRADIENT;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.icon {
  stroke: url(#paint0_GRADIENT_PRIMARY_DEFAULT);
}

.button:hover > .icon {
  stroke: url(#paint0_GRADIENT_PRIMARY_HOVER);
}

.button:hover > .buttonText {
  background: GRADIENT_PRIMARY_HOVER;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

@media screen and (max-width: MOBILE_WIDTH) {
  .button {
    bottom: 2rem;
    width: 48px;
    height: 48px;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .buttonText {
    display: none;
  }
}
