.screen {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  z-index: 2;
}

.padded {
  padding: 0.5rem 1rem;
}

.transparentBackground {
  composes: screen;
  position: absolute;
  background-color: #F8FAFC;
  opacity: 0.5;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1;
}

.transparent {
  position: absolute;
  width: 100%;
  height: 100%;
}