@value breakpoints: 'styles/breakpoints.module.css';
@value TABLET_WIDTH from breakpoints;

.fitnessChallengeInterstitial {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.headerContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.overline {
  font-size: 0.875rem;
  font-weight: 800;
  letter-spacing: 0.175em;
  margin-bottom: 1rem;
  text-align: left;
  background: linear-gradient(270deg, #E57054 0%, #85254E 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.header {
  font-size: 1.375rem;
  font-weight: 700;
  margin-bottom: 1rem;
  text-align: left;
}

.noteContainer {
  font-size: 1rem;
  font-weight: 600;
  margin-bottom: 1.5rem;
  text-align: left;
}

.coachContainer {
  position: relative;
  width: 10rem;
  height: 10rem;
}

.coach {
  width: 10rem;
  height: 10rem;
  border-radius: 6.25rem;
  border: 8px solid #F5F8FB;
  box-shadow: 0px 10px 30px 0px rgba(0, 0, 0, 0.10);
}

.bubbleContainer {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  transform: translate(-50%, 60%);
  z-index: 1;
}

@media screen and (min-width: TABLET_WIDTH) {
  .fitnessChallengeInterstitial {
    width: 600px;
  }

  .headerContainer {
    align-items: center;
  }

  .overline {
    text-align: center;
  }

  .header {
    text-align: center;
    font-size: 1.75rem;
    font-weight: 600;
  }

  .fitnessChallengeText {
    font-weight: 800;
  }

  .noteContainer {
    text-align: center;
  }
}