@value colors: 'styles/colors.module.css';
@value SOLID_BRICK_RED, BLUE, TEAL, RED, GREY_5, WHITE, NIGHT_BLUE, PURPLE, ORANGE, SILVER, MID_GREY, GREEN, LIGHTER_GREY, YELLOW, BLACK, CHARCOAL, LIGHT_BLUE from colors;

.unstyled {
  border: none;
  background-color: transparent;
  padding: 0;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  background: none;
  outline: none;
  border: none;
  user-select: none;
  font-size: 1rem;
  color: CHARCOAL;
}

.sharedBase {
  background-color: transparent;
  outline: none;
  text-decoration: none;
  text-align: center;
  line-height: normal;
  font-size: 1rem;
  font-weight: 400;
  border-style: solid;
}

.base {
  composes: sharedBase;
  padding: 0.5rem 1rem;
  transition: transform 0.2s;
  letter-spacing: 0.5px;
  border-radius: 3px;
  border-width: 1px;
}

.baseV2 {
  composes: sharedBase;
  border-width: 2px;
}

.smallV2 {
  padding: 0.5rem 1rem;
  border-radius: 1.875rem;
  font-size: 1rem;
}

.mediumV2 {
  padding: 1rem 1.875rem;
  border-radius: 1.875rem;
  font-size: 1.125rem;
}

.small {
  padding: 0.3rem 0.6rem;
  font-size: 0.9rem;
  border-radius: 2px;
}

.large {
  padding: 1rem 1.5rem;
  font-size: 1.2rem;
  border-radius: 4px;
}

@media (hover: hover) {
  .sharedBase:hover {
    cursor: pointer;
  }

  .unstyled:hover {
    cursor: pointer;
  }
}

.hollowBlack {
  color: BLACK;
  border-color: BLACK;
}

.hollowBlue {
  color: BLUE;
  border-color: BLUE;
}

.hollowWhite {
  color: WHITE;
  border-color: WHITE;
}

.hollowRed {
  color: RED;
  border-color: RED;
}

.hollowGrey {
  color: GREY_5;
  border-color: GREY_5;
}

.hollowLightGrey {
  color: LIGHTER_GREY;
  border-color: LIGHTER_GREY;
}

.hollowTeal {
  color: TEAL;
  border-color: TEAL;
}

.hollowNightBlue {
  color: NIGHT_BLUE;
  border-color: NIGHT_BLUE;
}

.hollowPurple {
  color: PURPLE;
  border-color: PURPLE;
}

.hollowOrange {
  color: ORANGE;
  border-color: ORANGE;
}

.whiteOrange {
  color: ORANGE;
  border-color: WHITE;
  background-color: WHITE;
}

.whitePurple {
  color: PURPLE;
  border-color: WHITE;
  background-color: WHITE;
}

.hollowMidGrey {
  color: MID_GREY;
  border-color: MID_GREY;
}

.hollowGreen {
  color: GREEN;
  border-color: GREEN;
}

.solidTeal {
  color: WHITE;
  background-color: TEAL;
  border-color: TEAL;
}

.solidGreen {
  color: WHITE;
  background-color: GREEN;
  border-color: GREEN;
}

.solidBlue {
  color: WHITE;
  background-color: BLUE;
  border-color: BLUE;
}

.solidBlack {
  color: WHITE;
  background-color: BLACK;
  border-color: BLACK;
}

.solidRed {
  color: WHITE;
  background-color: RED;
  border-color: RED;
}

.solidSilver {
  color: CHARCOAL;
  background-color: SILVER;
  border-color: SILVER;
}

.solidGrey {
  color: WHITE;
  background-color: GREY_5;
  border-color: GREY_5;
}

.solidPurple {
  color: WHITE;
  background-color: PURPLE;
  border-color: PURPLE;
}

.solidOrange {
  color: WHITE;
  background-color: ORANGE;
  border-color: ORANGE;
}

.brickRed {
  color: WHITE;
  background-color: SOLID_BRICK_RED;
  border-color: SOLID_BRICK_RED;
}

.solidYellow {
  color: BLACK;
  background-color: YELLOW;
  border-color: YELLOW;
}

.solidMidGrey {
  background-color: MID_GREY;
  border-color: MID_GREY;
}

.solidLightBlue {
  background-color: LIGHT_BLUE;
  border-color: LIGHT_BLUE;
}

.disabled {
  opacity: 0.6;
  pointer-events: none;
}

.homepageButton {
  display: inline-block;
  font-weight: 400;
  color: SILVER;
  text-align: center;
  vertical-align: middle;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 5px 10px;
  font-size: 18px;
  line-height: 1.5;
  border-radius: 100px;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.homepageButtonPrimary {
  background-color: ORANGE;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
}

.homepageButtonPrimary:hover {
  /* TODO implement */
  /* background-color: darken(ORANGE, 10); */
}
