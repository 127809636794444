@value colors: 'styles/colors.module.css';
@value PURPLE, PLACEHOLDER_GREY from colors;

.container {
  position: relative;
}

.selectV2 {
  display: block;
  font-size: 1.125rem;
  padding: 0.6875rem 2rem 0.6875rem 0;
  width: 100%;
  max-width: 100%;
  margin: 0;
  border: none;
  border-bottom: 1px solid rgba(0, 0, 0, 0.25);
  box-shadow: 0 1px 0 1px rgba(0, 0, 0, 0.04);
  border-radius: 0;
  appearance: none;
  background-color: transparent;
  outline: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  -webkit-box-shadow: none;
}

.selectV2::-ms-expand {
  display: none;
}

.selectV2:hover {
  border-color: PURPLE;
}

.selectV2:focus {
  border-color: PURPLE;
  box-shadow: 0 0 1px 3px rgba(59, 153, 252, 0.7);
  box-shadow: 0 0 0 3px -moz-mac-focusring;
  color: #222;
  outline: none;
  border-color: inherit;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.selectV2 .option {
  font-weight: normal;
}

.absolute {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  pointer-events: none;
}

.placeholder {
  composes: absolute;
  left: 0;
  color: PLACEHOLDER_GREY;
  transition: opacity 0.5s;
}

.placeholderFadeIn {
  opacity: 1;
}

.placeholderFadeOut {
  opacity: 0;
}

.placeholderVisible {
  opacity: 1;
  transition: none;
}

.placeholderHidden {
  opacity: 0;
  transition: none;
}

.arrowIcon {
  composes: absolute;
  right: 0.5rem;
  width: 1rem;
}

.select:disabled {
  opacity: 0.9; /* hard to read at default 0.7 */
}
