@value colors: 'styles/colors.module.css';
@value PLACEHOLDER_GREY from colors;
@value MARGIN: 1.1875rem;

.container {
  width: 18.8125rem;
}

.label {
  color: PLACEHOLDER_GREY;
  text-transform: uppercase;
  font-size: 0.8125rem;
  letter-spacing: 0.02rem;
}

.inputs {
  display: flex;
  justify-content: space-between;
}

.select {
  flex: 1;
}

.select:first-child {
  margin-right: MARGIN;
}

.select:last-child {
  margin-left: MARGIN;
}
