@value colors: 'styles/colors.module.css';
@value SUB_TEXT_GREY from colors;

.option {
  display: flex;
  margin-bottom: 1.625rem;
}

.labelWrapper {
  max-width: 20rem;
  margin-left: 1.25rem;
  flex: 1;
}

.label {
  font-size: 1.125rem;
  width: max-content;
  max-width: 100%;
}

.subText {
  color: SUB_TEXT_GREY;
  margin-top: 0.375rem;
}
