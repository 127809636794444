.fsa {
  display: flex;
  padding: 0.5rem 0.75rem;
  justify-content: center;
  align-items: center;
  gap: 0.625rem;
  border-radius: 0.625rem;
  border: 0.0625rem solid #B9EBCA;
  background: #DEFFE9;
  color: #00A442;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
  text-align: center;
}