@value colors: 'styles/colors.module.css';
@value ORANGE from colors;

.container {
  width: 18.8125rem;
  max-width: 100%;
}

.input {
  composes: baseV2 from 'styles/inputs.module.css';
  width: 100%;
}

.error {
  color: ORANGE;
  font-size: 0.9rem;
  margin-top: 0.5rem;
}
